import React from "react";
import "../styles/Home.css";
import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import config from "../utils/config";
import CustomButton from "../components/CustomButton/CustomButton";
import AllTrackCards from "../components/CardsComponent/TrackCard/AllTrackCards";

const Home = () => {
  const image1 = require("../assets/tempimages/1.webp");
  const image2 = require("../assets/tempimages/2.webp");
  const image3 = require("../assets/tempimages/3.webp");

  const packCards = [
    // {
    //   image: `${config.s3imgUrl}trackimages/sampleimage.png`,
    //   title: "Happy Diwali",
    //   buttonText: "Explore Tracks",
    //   buttonLink: "#",
    //   totalTracks: 10,
    // },
    // {
    //   image: `${config.s3imgUrl}trackimages/sampleimage.png`,
    //   title: "Happy Diwali",
    //   buttonText: "Explore Tracks",
    //   buttonLink: "#",
    //   totalTracks: 12,
    // },
    // {
    //   image: `${config.s3imgUrl}trackimages/sampleimage.png`,
    //   title: "Happy Diwali",
    //   buttonText: "Explore Tracks",
    //   buttonLink: "#",
    //   totalTracks: 14,
    // },
    {
      image: image1,
      title: "Grand - Reels Mania",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: image2,
      title: "Grand - Year End Celebrations",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: image3,
      title: "Grand - Where's The Party",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 14,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 14,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 14,
    },
  ];

  const brandCards = [
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
    { image: `${config.s3imgUrl}trackimages/sampleimage.png` },
  ];

  const featureTracks = [
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
  ];

  const offerCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleyrf.png`,
      title: "Offers & Discounts",
      description:
        "Don't miss out on this trend-setting opportunity! For a limited time...",
      buttonText: "Explore Tracks",
    },
    {
      image: `${config.s3imgUrl}trackimages/samplepopularsong.png`,
      title: "Extra 10% OFF on Popular Songs",
      description:
        "Don't miss out on this trend-setting opportunity! For a limited time, you can...",
      buttonText: "Explore Tracks",
    },
  ];

  const trendingTracks = [
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/nashe.png`,
      title: "Nashe Si Chadh Gayi",
      description: "Benny Dayal",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
  ];

  const mainBanner = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage1.png`,
      buttonText: "Explore Packs",
      buttonLink: "#",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage2.png`,
      buttonText: "Explore Packs",
      buttonLink: "#",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage3.png`,
      buttonText: "Explore Packs",
      buttonLink: "#",
    },
  ];

  return (
    <div className="home-page-parent-container">
      {/* banner section */}
      <div className="banner-container outer">
        {mainBanner.map((banner, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${banner.image})`,
            }}
            className="banner-card-img"
          >
            {/* <h1>Exclusive Fashion Packs</h1> */}
            <CustomButton
              text={banner.buttonText}
              backgroundColor="#C6349B"
              borderRadius="50px"
              borderColor="none"
              width="212px"
              height="44px"
              textColor="#fff"
              iconColor="#fff"
              fontSize="16px"
              fontFamily="Inter"
              fontWeight="bold"
              icon={<IoIosArrowForward />}
              justifyContent="space-between"
            />
          </div>
        ))}
      </div>
      {/* discount banner section */}
      <div className="discountBanner-container outer">
        <img
          src={`${config.s3imgUrl}trackimages/discountbanner.png`}
          alt="discountBanner"
        />
      </div>
      {/* top packs container */}
      <div className="top-packs-container outer-full">
        <div className="inner">
          <h1>Explore Our Top Packs</h1>
          <div className="top-packs-description-view">
            <h2>Buy this track at a discounted price in these packs</h2>
            <div className="top-packs-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div>
          </div>
          <div className="top-packs-cards-container">
            {packCards.map((pack, index) => (
              <div key={index} className="top-pack-card">
                <h1>{pack.title}</h1>
                <div
                  className="top-pack-card-img"
                  style={{
                    backgroundImage: `url(${pack.image})`,
                  }}
                >
                  <div className="top-card-like-info">
                    <div className="top-like-icon">
                      <FaRegHeart size={24} color="#fff" />
                    </div>
                    <div className="top-card-track-total">
                      <p>{pack.totalTracks}</p>
                      <span>TRACKS</span>
                    </div>
                  </div>
                </div>
                <div className="top-pack-price-button">
                  <div className="top-pack-price-container">
                    <div className="top-pack-discount-price">
                      <div className="top-pack-discount-amount">
                        <span>₹</span>
                        <p>49,999</p>
                      </div>
                      <div className="top-pack-discount-percentage">
                        50% Off
                      </div>
                    </div>
                    <div className="top-pack-cost-price">
                      <span>₹79,000</span>
                    </div>
                  </div>
                  <div className="top-explore-track-button">
                    <CustomButton
                      text={pack.buttonText}
                      backgroundColor="#fff"
                      borderRadius="50px"
                      borderColor="1px solid #C6349B"
                      width="179px"
                      height="44px"
                      textColor="#C6349B"
                      iconColor="#C6349B"
                      fontSize="15px"
                      fontFamily="Inter"
                      fontWeight="bold"
                      icon={<IoIosArrowForward />}
                      justifyContent="space-between"
                    />
                    <CustomButton
                      icon={<RiShoppingCart2Line />}
                      isIconOnly={true}
                      backgroundColor="#C6349B"
                      iconColor="#fff"
                      borderRadius="50px"
                      height="46px"
                      width="46px"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* trending section */}
      <div className="trending-now-container outer">
        <div className="trending-song-container">
          <div className="trending-views">
            <p>200k Views in One Day!</p>
            <div className="trending-icon">
              <img
                src={`${config.s3imgUrl}icons/trendwhiteicon.png`}
                alt="trending"
              />
            </div>
          </div>
          <div className="trending-image">
            <img
              src={`${config.s3imgUrl}trackimages/trendingimage.png`}
              alt="trendingimage"
            />
          </div>
        </div>
        <div className="trending-track-list-container">
          <h1>
            Trending Now{" "}
            <span>
              <img
                className="trending-icon-header"
                src={`${config.s3imgUrl}icons/trendwhiteicon.png`}
                alt="trending"
              />
            </span>
          </h1>
          <div className="trending-description-view">
            <h2>
              Don't miss out on this trend-setting opportunity! For a limited
              time, you can...
            </h2>
            <div className="trending-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div>
          </div>
          <div className="trending-track-cards">
            {trendingTracks.slice(0, 4).map((track, index) => (
              <div key={index} className="trending-track-card">
                <div
                  className="trending-track-card-image"
                  style={{
                    backgroundImage: `url(${track.image})`,
                  }}
                ></div>
                <div className="trending-track-card-text">
                  <div className="trending-track-title">
                    <h1>{track.title}</h1>
                    <h2>{track.description}</h2>
                  </div>
                  <div className="trending-track-price-container">
                    <div className="trending-track-discount-price">
                      <div className="trending-track-discount-amount">
                        <span>₹</span>
                        <p>19,999</p>
                      </div>
                      <div className="trending-track-discount-percentage">
                        50% Off
                      </div>
                    </div>
                    <div className="trending-track-cost-price">
                      <span>₹79,000</span>
                    </div>
                  </div>
                  <div className="trending-explore-track-button">
                    <div>
                      <CustomButton
                        text={track.buttonText}
                        backgroundColor="#fff"
                        borderRadius="50px"
                        borderColor="1px solid #434343"
                        width="167px"
                        height="37px"
                        textColor="#434343"
                        iconColor="#434343"
                        fontSize="14px"
                        fontFamily="Inter"
                        fontWeight="bold"
                        icon={<IoIosArrowForward />}
                        justifyContent="space-between"
                      />
                    </div>
                    <div>
                      <CustomButton
                        icon={<RiShoppingCart2Line />}
                        isIconOnly={true}
                        backgroundColor="#434343"
                        iconColor="#fff"
                        borderRadius="50px"
                        height="37px"
                        width="37px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* explore by brand category */}
      <div className="brand-category-container outer-full">
        <div className="inner">
          <h1>Explore By Brand Category</h1>
          <div className="brand-description-view">
            <h2>On your first Instagram Trending Track Purchase </h2>
            <div className="brand-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div>
          </div>
          <div className="brand-card-container">
            {brandCards.slice(0, 10).map((brand, index) => (
              <div
                key={index}
                style={
                  {
                    // backgroundImage: `url(${brand.image})`,
                  }
                }
                className="brand-card-img"
              ></div>
            ))}
          </div>
        </div>
      </div>
      {/* offer section */}
      <div className="offer-container outer">
        {offerCards.map((offer, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${offer.image})`,
            }}
            className="offer-card-img"
          >
            <div className="offercard-title">
              <h1>{offer.title}</h1>
            </div>
            <div className="offercard-description">
              <h2>{offer.description}</h2>
            </div>
            <div>
              <CustomButton
                text={offer.buttonText}
                backgroundColor="#C6349B"
                borderRadius="30px"
                borderColor="none"
                width="212px"
                height="44px"
                textColor="#fff"
                iconColor="#fff"
                fontSize="16px"
                fontFamily="Inter"
                fontWeight="bold"
                icon={<IoIosArrowForward />}
                justifyContent="space-between"
              />
            </div>
          </div>
        ))}
      </div>
      {/* feature tracks section */}
      <div className="feature-track-container outer-full">
        <div className="inner">
          <h1>Featured Tracks</h1>
          <div className="feature-track-description-view">
            <h2>On your first Instagram Trending Track Purchase</h2>
            {/* <div className="feature-track-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#fff" />
              </p>
            </div> */}
          </div>
          <div className="feature-track-cards">
            {featureTracks.map((track, index) => (
              <div key={index} className="feature-track-card">
                <div
                  className="feature-track-card-image"
                  style={{
                    backgroundImage: `url(${track.image})`,
                  }}
                ></div>
                <div className="feature-track-card-text">
                  <div className="feature-track-title">
                    <h1>{track.title}</h1>
                    <h2>{track.description}</h2>
                  </div>
                  <div className="feature-track-price-container">
                    <div className="feature-track-discount-price">
                      <div className="feature-track-discount-amount">
                        <span>₹</span>
                        <p>19,999</p>
                      </div>
                      <div className="feature-track-discount-percentage">
                        50% Off
                      </div>
                    </div>
                    <div className="feature-track-cost-price">
                      <span>₹79,000</span>
                    </div>
                  </div>
                  <div className="feature-explore-track-button">
                    <div>
                      <CustomButton
                        text={track.buttonText}
                        backgroundColor="#fff"
                        borderRadius="50px"
                        borderColor="none"
                        width="186px"
                        height="37px"
                        textColor="#212328"
                        iconColor="#212328"
                        fontSize="16px"
                        fontFamily="Inter"
                        fontWeight="550"
                        icon={<IoIosArrowForward />}
                        justifyContent="space-between"
                      />
                    </div>
                    <div>
                      <CustomButton
                        icon={<RiShoppingCart2Line />}
                        isIconOnly={true}
                        backgroundColor="#fff"
                        iconColor="#000"
                        borderRadius="50px"
                        height="37px"
                        width="37px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* all songs container */}
      <AllTrackCards viewall="true" />
    </div>
  );
};

export default Home;
