import React, { useState, useEffect, useCallback } from "react";
import "./AllTrackCard.css";
import { IoIosArrowForward } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import SearchBar from "../../SearchBar/SearchBar";
import CustomButton from "../../CustomButton/CustomButton";
import { getAllTrack } from "../../../service/TrackListingService";
import { Link } from "react-router-dom";

const AllTrackCards = ({ viewall }) => {
  const [allTrackList, setAllTrackList] = useState([]);
  const [loading, setLoading] = useState(false); // To control the loading state
  const [page, setPage] = useState(1); // For pagination
  const [totalAllTracks, setTotalAllTracks] = useState(0); // To store total number of items

  // Fetch tracks with pagination
  const fetchTracks = useCallback(async (page) => {
    setLoading(true);
    try {
      const response = await getAllTrack(page);
    //   console.log(response);

      const data = response.data.tracks || [];
      setTotalAllTracks(response.data.totalTracks);
      setAllTrackList((prevTrackList) => [...prevTrackList, ...data]); // Append new data to existing list
      setLoading(false);
    } catch (error) {
      console.error("Error fetching track list:", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTracks(page); // Initial fetch when component mounts
  }, [page, fetchTracks]);

  // Handle scrolling to detect when we reach the bottom of the container
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    // console.log(totalAllTracks);
    if (bottom && !loading && allTrackList.length <= totalAllTracks) {
      setPage((prevPage) => prevPage + 1); // Load the next page
    }
  };

  useEffect(() => {
    const container = document.querySelector(".all-songs");
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [loading]); // Ensure to remove the listener when loading state changes

  // Function to format price with commas
  const formatPrice = (price) => {
    return price.toLocaleString(); // Formats number with commas (e.g., 40000 -> 40,000)
  };

  const allTracks = allTrackList.map((track) => {
    const { costPrice, sellingPrice } = track.SKU[0]; // Extract costPrice and sellingPrice
    const discountPercent = (
      ((costPrice - sellingPrice) / costPrice) *
      100
    ).toFixed(0); // Calculate discount percentage

    // Extract all artist names from the tracksArtistRole array
    const artistNames = track.tracksArtistRole
      .map((artist) => artist.artist.name)
      .join(", ");

    return {
      image: track.imageUrl,
      title:
        track.name.length > 18
          ? track.name.substring(0, 17) + "..."
          : track.name,
      description:
        artistNames.length > 18
          ? artistNames.substring(0, 29) + "..."
          : artistNames,
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: discountPercent || "50% OFF",
      costPrice: formatPrice(costPrice) || "19,999",
      sellingPrice: formatPrice(sellingPrice) || "79,000",
      name: track.name_slug,
      trackCode: track.trackCode,
    };
  });

  // options for dropwdown
  const filterOptions = [
    { value: "dil-diyan-gallan", label: "Dil Diyan Gallan" },
    { value: "khuda-jaane", label: "Khuda Jaane" },
    { value: "besharam-rang", label: "Besharam Rang" },
    { value: "nashe-si-chadh-gayi", label: "Nashe Si Chadh Gayi" },
    { value: "chand-sifarish", label: "Chand Sifarish" },
    { value: "sajde", label: "Sajde" },
    { value: "ghungroo", label: "Ghungroo" },
    { value: "tujh-mein-rab-dikhta-hai", label: "Tujh Mein Rab Dikhta Hai" },
    { value: "o-re-piya", label: "O Re Piya" },
    { value: "bulleya", label: "Bulleya" },
  ];
  return (
    // <div>
    <div className="all-songs-container inner">
      <div className="all-songs-filter">
        <div className="filter-contents">
          {/* <div className="inner allfilter-title">All Filters</div> */}
          <div className="filter-options">
            <p>Sort By:</p>
            <CustomDropdown
              options={filterOptions}
              placeholder="Select an option"
            />
            <div>
              <SearchBar
                placeholder="Search by track name"
                iconPosition="start"
              />
            </div>
          </div>
          <div className="filter-artist">
            <p>Artist:</p>
            {[...Array(5)].map((_, index) => (
              <div key={index} className="filter-artist-options">
                <input
                  type="checkbox"
                  id={`vehicle${index + 1}`}
                  name={`vehicle${index + 1}`}
                  value={`Option ${index + 1}`}
                />
                <label htmlFor={`vehicle${index + 1}`}>
                  Sub Category ({index + 1})
                </label>
              </div>
            ))}
          </div>

          <div className="filter-artist">
            <p>Genres:</p>
            {[...Array(5)].map((_, index) => (
              <div key={index} className="filter-artist-options">
                <input
                  type="checkbox"
                  id={`vehicle${index + 1}`}
                  name={`vehicle${index + 1}`}
                  value={`Option ${index + 1}`}
                />
                <label htmlFor={`vehicle${index + 1}`}>
                  Sub Category ({index + 1})
                </label>
              </div>
            ))}
          </div>
          <div style={{ marginTop: "30px" }}>Price filters in progress</div>
          <div className="filter-artist">
            <p>Language:</p>
            {[...Array(5)].map((_, index) => (
              <div key={index} className="filter-artist-options">
                <input
                  type="checkbox"
                  id={`vehicle${index + 1}`}
                  name={`vehicle${index + 1}`}
                  value={`Option ${index + 1}`}
                />
                <label htmlFor={`vehicle${index + 1}`}>
                  Sub Category ({index + 1})
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="alltrack-title">
          <p>All Tracks</p>
          {viewall && (
            <Link to="/tracks">
              <div className="all-songs-viewall">
                <p>View All</p>
                <p>
                  <IoIosArrowForward color="#000" />
                </p>
              </div>
            </Link>
          )}
        </div>

        <div className="all-songs">
          {allTracks.map((track, index) => (
            <div key={index} className="allsong-track-card">
              <div
                className="allsong-track-card-image"
                style={{
                  backgroundImage: `url(${track.image})`,
                }}
              ></div>
              <div className="allsong-track-card-text">
                <div className="allsong-track-title">
                  <h1>{track.title}</h1>
                  <h2>{track.description}</h2>
                </div>
                {/* Price section with conditional rendering */}
                <div className="allsong-track-price-container">
                  {/* Only show discount and cost price if sellingPrice != costPrice */}
                  {track.sellingPrice !== track.costPrice && (
                    <div className="allsong-track-discount-price">
                      <div className="allsong-track-discount-amount">
                        <span>₹</span>
                        <p>{track.sellingPrice}</p>
                      </div>
                      <div className="allsong-track-discount-percentage">
                        {track.discountPercent}% Off
                      </div>
                    </div>
                  )}

                  {/* Only show cost price if sellingPrice != costPrice */}
                  {track.sellingPrice !== track.costPrice && (
                    <div className="allsong-track-cost-price">
                      <span>₹{track.costPrice}</span>
                    </div>
                  )}
                </div>
                {/* <div className="allsong-track-price-container">
                  <div className="allsong-track-discount-price">
                    <div className="allsong-track-discount-amount">
                      <span>₹</span>
                      <p>19,999</p>
                    </div>
                    <div className="allsong-track-discount-percentage">
                      50% Off
                    </div>
                  </div>
                  <div className="allsong-track-cost-price">
                    <span>₹79,000</span>
                  </div>
                </div> */}
                <div className="allsong-explore-track-button">
                  <div>
                    <Link
                      to={`/tracks/${encodeURIComponent(track.name)}/${
                        track.trackCode
                      }`}
                    >
                      <CustomButton
                        text={track.buttonText}
                        backgroundColor="#fff"
                        borderRadius="50px"
                        borderColor="1px solid #CCCCCC"
                        width="148px"
                        height="29px"
                        textColor="#272727"
                        iconColor="#272727"
                        fontSize="13px"
                        fontFamily="Inter"
                        fontWeight="550"
                        icon={<IoIosArrowForward />}
                        justifyContent="space-between"
                      />
                    </Link>
                  </div>
                  <div>
                    <CustomButton
                      icon={<RiShoppingCart2Line />}
                      isIconOnly={true}
                      backgroundColor="#272727"
                      iconColor="#fff"
                      borderRadius="50px"
                      height="28px"
                      width="28px"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          {loading && <div>Loading more...</div>}
          {/* Show loading indicator */}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default AllTrackCards;
