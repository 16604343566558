// CustomDropdown.js
import React from 'react';
import Select from 'react-select';
import './CustomDropdown.css'; // Importing the custom styles

// Custom styles for react-select
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: '#000',
        backgroundColor: state.isSelected ? '#000' : state.isFocused ? '#f5c4d0' : '#fff',
        padding: 10,
    }),
    control: (provided) => ({
        ...provided,
        borderColor: '#000',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#000',
        },
        borderRadius: '4px',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '4px',
        marginTop: 0,
        zIndex: 9999, // Ensuring dropdown appears above other elements
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '200px', // Set a max height for the dropdown list
        overflowY: 'auto',  // Enable scrolling if the list is too long
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#000',
    }),
};

// Example component for different use cases
const CustomDropdown = ({
    options,
    isMulti = false,
    isAsync = false,
    isDisabled = false,
    loadOptions = () => Promise.resolve([]),
    placeholder = 'Select...',
    onChange,
    value,
    formatOptionLabel,
    ...props
}) => {
    return (
        <Select
            styles={customStyles}
            options={options}
            isMulti={isMulti}
            isDisabled={isDisabled}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            formatOptionLabel={formatOptionLabel}
            loadOptions={isAsync ? loadOptions : undefined}
            {...props}
        />
    );
};

export default CustomDropdown;
