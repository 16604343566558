import React, { useState } from "react";
import "../styles/Header.css";
import SearchBar from "../components/SearchBar/SearchBar";
import config from "../utils/config";
import CustomButton from "../components/CustomButton/CustomButton";
import { MdMenu } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // STATE TO CHECK THE MOBILE VIEW ACTIVE STATUS
  const [isSearchActive, setIsSearchActive] = useState(false); //  STATE TO CHECK THE SEARCH BAR ACTIVE STATUS

  // FUNCTION TO TOGGLE THE MOBILE VIEW
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // FUNCTION TO TOGGLE THE SEARCH BAR
  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
  };

  return (
    <div className="header-container">
      {/* header-banner-about-section */}
      <div className="header-banner-about-section">
        <div className="header-banner">
          <img
            src={config.s3imgUrl + "icons/trendingwhiteicon.png"}
            alt="bannericon"
            className="banner-icon"
          />
          <p>Exclusive Launch Offer: 25% OFF ON ALL TRACKS</p>
        </div>
        <div className="header-about-tabs">
          <span>Pricing</span>
          <span>License</span>
          <span>FAQs</span>
          <span>Contact Us</span>
        </div>
      </div>

      {/* main header section */}
      <div className="header-main-section">
        <div className="header-logo-tabs">
          {/* Mobile menu icon and logo */}
          <div className="mobile-menu-logo">
            <MdMenu
              color="#434343"
              size={28}
              className="menu-icon"
              onClick={toggleMobileMenu}
            />
            {/* <img
              src={config.s3imgUrl + "icons/smashlogo.png"}
              alt="logo"
              className="header-logo"
            /> */}
            <Link to="/">
              <img
                src={config.s3imgUrl + "icons/smashlogo.png"}
                alt="logo"
                className="header-logo"
              />
            </Link>
          </div>

          {/* Mobile cart and search icons */}
          <div className="mobile-icons">
            <img
              src={config.s3imgUrl + "icons/carticon.png"}
              alt="cart"
              className="cart-icon"
            />
            <LuSearch
              size={24}
              color={isSearchActive ? "#C6349B" : "#434343"} // Change color if search is active
              onClick={toggleSearch}
              className="search-icon"
            />
          </div>

          {/* Header tabs for desktop */}
          <div className="header-tabs">
            <div className="header-tab-span">
              {/* <span>SmashHits</span>
              <span>Categories</span>
              <span>Occasions</span>
              <span>Genres</span>
              <span>Packs</span>
              <span>Filter Tracks</span> */}
              <Link to="/tracks">
                <span>Tracks</span>
              </Link>
              <Link to="/packlisting">
                <span>Packs</span>
              </Link>
            </div>
            <div>
              <SearchBar
                placeholder="Search on smash"
                iconPosition="start"
                width="332px"
                height="44px"
              />
            </div>
          </div>
        </div>

        {/* login and cart section */}
        <div className="header-login-cart">
          <div>
            <CustomButton
              text="Log In"
              borderRadius="30px"
              height="39px"
              borderColor="none"
              backgroundColor="#C6349B"
              width="172px"
              fontSize="14px"
              fontWeight="550"
              fontFamily="inter"
            />
          </div>
          <div className="header-cart">
            <img
              src={config.s3imgUrl + "icons/carticon.png"}
              alt="logo"
              className="cart-icon"
            />
          </div>
        </div>
      </div>

      {/* Conditional SearchBar for mobile view */}
      {isSearchActive && (
        <div className="mobile-search-bar">
          <SearchBar placeholder="Search on smash" iconPosition="start" />
        </div>
      )}

      {/* Mobile sidebar menu */}
      {isMobileMenuOpen && (
        <div className="mobile-menu-overlay">
          <div className="mobile-menu-content">
            <div className="close-icon">
              <IoCloseCircleOutline
                size={24}
                color="#434343"
                onClick={toggleMobileMenu}
              />
            </div>
            <span>SmashHits</span>
            <span>Categories</span>
            <span>Occasions</span>
            <span>Genres</span>
            <span>Packs</span>
            <span>Filter Tracks</span>
            <span>Pricing</span>
            <span>License</span>
            <span>FAQs</span>
            <span>Contact Us</span>

            <div className="custom-button-container">
              <CustomButton
                text="Log In"
                borderRadius="30px"
                height="39px"
                borderColor="none"
                backgroundColor="#C6349B"
                width="100%"
                fontSize="14px"
                fontWeight="550"
                fontFamily="inter"
                className="custom-button"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
