import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import Header from "./screens/Header";
import Home from "./screens/Home";
import TrackPage from "./screens/TrackPage";
import TrackListing from "./screens/TrackListing";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="parentDiv">
        {/* Always visible component */}
        <Header />

        {/* Route-based pages */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tracks/:name/:trackCode" element={<TrackPage />} />
          <Route path="/tracks" element={<TrackListing />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
