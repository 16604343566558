import React, { useState, useEffect, useRef } from "react";
import "./SearchBar.css";
import { LuSearch } from "react-icons/lu";
import keywordData from "./keywordData.json";

// Debounce function
function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}

const SearchBar = ({
  placeholder = "Search",
  iconPosition = "start",
  width,
  height,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [selectedKeywordId, setSelectedKeywordId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1); // Tracks which option is currently selected with keyboard

  const searchContainerRef = useRef(null);
  const activeOptionRef = useRef(null); // Ref to track the active option for scrolling

  console.log(selectedKeywordId, "this is selected keyword id temp log");

  useEffect(() => {
    // Close dropdown when clicking outside
    function handleClickOutside(event) {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
        setActiveIndex(-1); // Reset the active index
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Debounced input change handler
  const debouncedInputChange = debounce((value) => {
    if (value.trim() !== "") {
      // Simulate API call by filtering keywordData
      const filtered = keywordData.filter((item) =>
        item.keyword.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredKeywords(filtered);
      setDropdownVisible(true);
      setActiveIndex(-1); // Reset active index when typing
    } else {
      setDropdownVisible(false);
    }
  }, 300); // Adjust delay as needed

  useEffect(() => {
    // Keyboard interactions only when the dropdown is visible
    function handleKeyDown(event) {
      if (dropdownVisible) {
        if (event.key === "ArrowDown") {
          setActiveIndex((prev) =>
            prev < filteredKeywords.length - 1 ? prev + 1 : prev
          );
        } else if (event.key === "ArrowUp") {
          setActiveIndex((prev) => (prev > 0 ? prev - 1 : 0));
        } else if (event.key === "Enter" && activeIndex >= 0) {
          handleSelectKeyword(filteredKeywords[activeIndex].id);
        } else if (event.key === "Escape") {
          setDropdownVisible(false);
          setActiveIndex(-1); // Reset the active index
        }
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dropdownVisible, filteredKeywords, activeIndex]);

  useEffect(() => {
    // Scroll the active option into view when it's selected with keyboard
    if (activeOptionRef.current) {
      activeOptionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeIndex]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    debouncedInputChange(value); // Apply debounce to input change handling
  };

  const handleSelectKeyword = (keywordId) => {
    const selectedKeyword = filteredKeywords.find(
      (item) => item.id === keywordId
    );
    setSearchValue(selectedKeyword.keyword); // Update input with selected keyword
    setSelectedKeywordId(keywordId); // Store selected keyword's id
    setDropdownVisible(false); // Close dropdown immediately
  };

  return (
    <div ref={searchContainerRef} className="search-container">
      {/* <div
            ref={searchContainerRef}
            className={`search-container ${dropdownVisible ? 'dropdown-open' : ''}`}
        > */}
      <div
        style={{ width: width || "100%", height: height || "40px" }}
        className={`search-bar icon-${iconPosition}`}
      >
        {iconPosition === "start" && (
          <LuSearch color="#d6d8de" size={22} className="search-icon" />
        )}
        <input
          type="text"
          className="search-input"
          placeholder=" "
          value={searchValue}
          onChange={handleInputChange}
        />
        <label className="search-label">{placeholder}</label>
        {iconPosition === "end" && (
          <LuSearch color="#d6d8de" size={24} className="search-icon" />
        )}
      </div>
      {/* 
            {dropdownVisible && (
                <ul className="dropdown">
                    {filteredKeywords.length > 0 ? (
                        filteredKeywords.map((keyword, index) => (
                            <li
                                key={keyword.id}
                                ref={activeIndex === index ? activeOptionRef : null}
                                className={activeIndex === index ? 'active' : ''}
                                onClick={() => handleSelectKeyword(keyword.id)}
                            >
                                {keyword.keyword}
                            </li>
                        ))
                    ) : (
                        <li className="no-data">No data found</li>
                    )}
                </ul>
            )} */}
    </div>
  );
};

export default SearchBar;
